













































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ApplyJobs extends Vue {
  logo: string = require("@/assets/power/img/logo-b.png");
  form = {};
}
